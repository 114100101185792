import React from 'react';
import Image from "gatsby-plugin-sanity-image";
import classnames from 'classnames';

import './Avatar.scss';

const Avatar = props => {

  const { people, className, thumbnailSize = 24 } = props;

  return (
    <div className={classnames("Avatar", className)}>
      {people.image &&
        <Image
          {...people.image}
          width={thumbnailSize}
          height={thumbnailSize}
          alt={`Avatar of ${people.name}`}
          className="AvatarThumbnail"
        />
      }
      <div>
        <span className="AvatarName">
          {people.name}
        </span>
        {people.jobtitle && 
          <span className="AvatarJobTitle">
            {people.jobtitle}
            {people.company?.name &&
              <>, {people.company.name}</>
            }
          </span>
        }
      </div>
    </div>
  );
}

export default Avatar;
