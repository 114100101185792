import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import Image from 'gatsby-plugin-sanity-image';
import Button from './Button';
import { getImageDimensions } from '@sanity/asset-utils';

import Container from './Container';

import './Hero.scss';

const Hero = (props) => {
  let {
    children,
    overlappingImage,
    rightImage,
    className,
    textAlign,
    showStickyNav,
    youtubeId,
  } = props;

  if (overlappingImage?.image) {
    var { width, height } = getImageDimensions(overlappingImage.image);
  }

  if (youtubeId || rightImage) textAlign = 'Left';

  const containerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [activeId, setActiveId] = useState('');

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsSticky(!entry.isIntersecting);
  };

  const options = { threshold: [1] };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  const onIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) setActiveId(entry.target.id);
    });
  };

  const [headingsLevel2, setHeadingsLevel2] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      root: null,
      rootMargin: `0px`,
      threshold: 0,
    });
    if (headingsLevel2?.length > 0)
      headingsLevel2.forEach((heading) => observer.observe(heading));

    return () => {
      if (headingsLevel2?.length > 0)
        headingsLevel2.forEach((heading) => observer.unobserve(heading));
    };
  }, [headingsLevel2, options]);

  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll('h2'));

    setHeadingsLevel2(headingElements);
  }, []);

  return (
    <>
      <section
        className={classnames(
          'Hero',
          overlappingImage ? 'HeroWithOverlappingImage' : null,
          rightImage ? 'HeroWithRightImage' : null,
          textAlign ? `HeroTextAlign${textAlign}` : null,
          className
        )}
      >
        <div className="Pattern PatternBottom" />
        <div className="Pattern PatternTop" />

        <Container>
          {rightImage?.image && (
            <Image
              {...rightImage.image}
              alt={rightImage.altText || ''}
              className="HeroRightImage"
            />
          )}
          <div className="HeroContainerContent">{children}</div>
          <div className="HeroContainerMedia">
            {youtubeId && (
              <iframe
                width="560"
                height="315"
                className="HeroContainerMediaVideoFrame"
                src={`https://www.youtube.com/embed/${youtubeId}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            )}
          </div>
        </Container>
      </section>
      {showStickyNav && (
        <nav
          className={classnames(
            'HeroFixedNav',
            isSticky ? 'HeroFixedNavSticky' : ''
          )}
          ref={containerRef}
        >
          <a href="/" className={classnames('HeroFixedNavLogo')}>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 40C31.046 40 40 31.046 40 20C40 8.954 31.046 0 20 0C8.954 0 0 8.954 0 20C0 31.046 8.954 40 20 40Z"
                fill="#00D1CA"
              />
              <path
                d="M13.044 20C13.044 16.191 16.191 13.043 20.149 13.043C21.215 13.0391 22.2683 13.2738 23.2318 13.7299C24.1953 14.186 25.0444 14.852 25.717 15.679C25.9015 15.9083 26.1295 16.0989 26.3878 16.2399C26.6462 16.3809 26.9298 16.4695 27.2224 16.5006C27.5151 16.5318 27.811 16.5048 28.0932 16.4214C28.3754 16.3379 28.6384 16.1995 28.867 16.0142C29.0957 15.8289 29.2854 15.6002 29.4255 15.3414C29.5656 15.0826 29.6532 14.7986 29.6833 14.5059C29.7134 14.2131 29.6854 13.9173 29.6009 13.6354C29.5165 13.3535 29.3772 13.091 29.191 12.863C28.0992 11.5188 26.7205 10.4357 25.1561 9.69309C23.5916 8.95047 21.8808 8.56709 20.149 8.57101C13.789 8.57101 8.57202 13.655 8.57202 20C8.57202 26.345 13.789 31.428 20.149 31.428C23.801 31.428 27.069 29.755 29.191 27.137C29.5646 26.6763 29.7398 26.0861 29.6782 25.4962C29.6166 24.9063 29.3232 24.3651 28.8625 23.9915C28.4018 23.618 27.8116 23.4427 27.2217 23.5043C26.6318 23.5659 26.0906 23.8593 25.717 24.32C25.0444 25.147 24.1953 25.813 23.2318 26.2691C22.2683 26.7252 21.215 26.9599 20.149 26.956C16.191 26.956 13.044 23.808 13.044 20Z"
                fill="#1A263E"
              />
              <path
                d="M29.193 22.236C29.7861 22.236 30.3548 22.0004 30.7741 21.5811C31.1935 21.1617 31.429 20.593 31.429 20C31.429 19.407 31.1935 18.8382 30.7741 18.4189C30.3548 17.9996 29.7861 17.764 29.193 17.764C28.6 17.764 28.0313 17.9996 27.6119 18.4189C27.1926 18.8382 26.957 19.407 26.957 20C26.957 20.593 27.1926 21.1617 27.6119 21.5811C28.0313 22.0004 28.6 22.236 29.193 22.236Z"
                fill="#1A263E"
              />
            </svg>
          </a>
          {headingsLevel2 && headingsLevel2.length > 0 && (
            <ul>
              {headingsLevel2.map((heading) => {
                const label = heading.innerText;
                const id = heading.getAttribute('id');
                return (
                  <li>
                    <a
                      href={`#${id}`}
                      className={activeId === id ? 'active' : ''}
                    >
                      {label}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </nav>
      )}
      {overlappingImage?.image && (
        <Container>
          <Image
            {...overlappingImage.image}
            width={width}
            height={height}
            alt={overlappingImage.altText || ''}
            className="HeroOverlappingImage"
          />
        </Container>
      )}
    </>
  );
};

const HeroTitle = (props) => {
  const { as: Component, children } = props;
  return <Component className="HeroTitle">{children}</Component>;
};

const HeroText = (props) => {
  const { children } = props;
  return <div className="HeroText">{children}</div>;
};

const HeroImage = (props) => {
  const { children } = props;
  return <div className="HeroImage">{children}</div>;
};

const HeroCallToActions = (props) => {
  const { callToActions } = props;
  if (!callToActions) return;

  return (
    <div className="HeroCallToActions">
      {callToActions.map((callToAction, index) => {
        const multipleCTAs = callToActions.length > 1;
        return (
          <Button
            to={callToAction.url}
            className={classnames(
              multipleCTAs && index === 0
                ? 'button-secondary'
                : 'corporate-light'
            )}
            key={callToAction._key}
          >
            {callToAction.label}
          </Button>
        );
      })}
      ;
    </div>
  );
};

HeroTitle.defaultProps = {
  as: 'h1',
};

export default Object.assign(Hero, {
  Title: HeroTitle,
  Text: HeroText,
  Image: HeroImage,
  CallToActions: HeroCallToActions,
});
