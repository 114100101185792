import React from 'react';
import { graphql } from 'gatsby';

import Metadata from '../components/Metadata';
import PageLayout from '../layouts/Page';
import Hero from '../components/Hero';
import HubspotFormPancake from '../components/pancakes/HubspotForm';
import Avatar from '../components/Avatar';

import './webinar-page.scss';

export const query = graphql`
  query WebinarQuery($id: String!) {
    webinar: sanityWebinar(_id: { eq: $id }) {
      _id
      webinarTitle
      hubspotFormID
      _rawDescription
      dateTime
      speakers {
        name
        jobtitle
        company {
          name
        }
        image {
          ...ImageWithPreview
        }
      }
      slug {
        current
      }
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;

const Speakers = ({ speakers }) => {
  return (
    <ul className="WebinarPageSpeakersList">
      {speakers.map((speaker) => (
        <li>
          <Avatar
            people={speaker}
            className="WebinarPageSpeaker"
            thumbnailSize={35}
          />
        </li>
      ))}
    </ul>
  );
};

const LocalDateTime = ({ date }) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const webinarDate = new Date(date);

  const webinarDay = webinarDate.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: userTimeZone,
  });
  const webinarTime = webinarDate.toLocaleString('en-US', {
    timeZone: userTimeZone,
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });

  return (
    <>
      {webinarDay} <span>@ {webinarTime}</span>
    </>
  );
};

const WebinarPage = (props) => {
  const { webinar } = props.data;

  return (
    <PageLayout>
      <Metadata
        title={webinar.seo?.metaTitle || webinar.webinarTitle}
        description={webinar.seo?.metaDescription}
        image={webinar.seo?.ogImage?.asset.url || null}
      />
      <Hero overlappingImage={true}>
        <Hero.Title>
          <span>Webinar</span>
          {webinar.webinarTitle}
        </Hero.Title>
      </Hero>
      <HubspotFormPancake
        blocks={webinar._rawDescription}
        formId={webinar.hubspotFormID}
        useChiliPiper={webinar.useChiliPiper}
        customChiliPiperRouter={webinar.customChiliPiperRouter}
      >
        {webinar.dateTime ? (
          <>
            <h2 className="WebinarPageDateTime">
              <LocalDateTime date={webinar.dateTime} />
            </h2>
            <h3 className="WebinarPageSpeakersHeading">Speakers:</h3>
          </>
        ) : (
          <h2 className="WebinarPageDateTime">
            {webinar.speakers?.length === 1 ? 'Speaker' : 'Speakers'}:
          </h2>
        )}

        <Speakers speakers={webinar.speakers} />
      </HubspotFormPancake>
    </PageLayout>
  );
};

export default WebinarPage;
