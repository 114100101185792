import React from 'react';
import { graphql } from 'gatsby';
import SanityPortableText from '../SanityPortableText';

import PancakeIntro from '../PancakeIntro';
import Pancake from './Pancake';
import Container from '../Container';
import HubspotForm from '../HubspotForm';
import Card from '../Card';
import classnames from 'classnames';

import './HubspotForm.scss';

const HubspotFormPancake = (props) => {
  const {
    title,
    text,
    blocks,
    formId,
    theme,
    children,
    className,
    useChiliPiper,
    customChiliPiperRouter,
  } = props;
  const containerSize = blocks === null ? 'Small' : null;

  return (
    <Pancake
      className={classnames('HubspotFormPancake', className)}
      theme={theme}
    >
      <Container size={containerSize}>
        {(title || text) && (
          <PancakeIntro title={title} text={text} textAlign="Left" />
        )}
        <div className="HubspotFormPancakeGrid">
          <div>
            {children}
            {blocks?.length > 0 && (
              <div className="HubspotFormPancakeContent">
                <SanityPortableText blocks={blocks} />
              </div>
            )}
          </div>
          {formId && (
            <Card
              theme="Light"
              paddingSize="Double"
              className="HubspotFormPancakeCard"
            >
              <Card.Content>
                <HubspotForm
                  formId={formId}
                  dontUseChiliPiper={!useChiliPiper}
                  customChiliPiperRouter={customChiliPiperRouter}
                />
              </Card.Content>
            </Card>
          )}
        </div>
      </Container>
    </Pancake>
  );
};

export const query = graphql`
  fragment SanityPancakeHubspotForm on SanityHubspotForm {
    _key
    _type
    pancakeIntro {
      pancakeText
      pancakeTitle
    }
    _rawPancakeText
    useChiliPiper
    customChiliPiperRouter
    hubspotFormID
    theme
  }
`;

export default HubspotFormPancake;
